
.style_all_content{
    background-color:#F6F7FB;;
    display: flex;
    text-align: center;
    justify-content: center;
}
.content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(183, 183, 183, 0.4);
    border-radius: 20px;

}
.title {
    font-family: Roboto;
    font-weight: 600;
    font-size: 30px;
    color:#36454F;

    text-align: center;
}
.form_inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.style_input {
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    width: 400px;
    height: 45px;
    border-radius: 10px;
    padding-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #A9AEB2;
    cursor: pointer;
}

.style_input_button {
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: Roboto;
    font-weight: 700;
    color:#36454F;
    font-size: 24px;
    background-color: #FFA500;
    border-color: #FFA500;
    width: 170px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
}
.style_input_button:hover{
    background-color: #FDCE0E;
}