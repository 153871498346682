.all_content {
    background-color: #F0FFF0;
    padding-bottom: 50px;

}

.table {
    margin-right: 15px;
    margin-left: 15px;
}

.all_button {
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 35px;
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-top: 15px;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 3px;
    border-color: black;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}

ul.pagination li a.active {
    background-color: #4CAF50;
    color: white;
    border-radius: 3px;
}

ul.pagination li a:hover:not(.active) {
    background-color: #ddd;
}

.all_filter_and_select {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.select {
    display: block;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    color: #444;
    line-height: 1.3;
    padding: .3em 1.4em .5em .4em;
    width: 120px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: .3em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

.select::-ms-expand {
    display: none;
}

.select:hover {
    border-color: #888;
}

.select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #7BA7AB;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.select option {
    font-weight: normal;
}

*[dir="rtl"] .select,
:root:lang(ar) .select,
:root:lang(iw) .select {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}

.select_wallets {
    display: block;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 600;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 190px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: .3em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

.select_wallets::-ms-expand {
    display: none;
}

.select_wallets:hover {
    border-color: #888;
}

.select_wallets:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #7BA7AB;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.select_wallets option {
    font-weight: normal;
}

*[dir="rtl"] .select_wallets,
:root:lang(ar) .select_wallets,
:root:lang(iw) .select_wallets {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}

.input_search {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    width: 120px;
    height: 40px;
    padding-left: 10px;
    border: 1px solid #aaa;
    border-radius: 7px 0px 0px 7px;
    outline: none;
    background-color: #fff;
    color: #9E9C9C;
}

.input_search::-ms-expand {
    display: none;
}

.input_search:hover {
    border-color: #888;
}

.input_search:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #7BA7AB;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.input_search option {
    font-weight: normal;
}

*[dir="rtl"] .input_search,
:root:lang(ar) .input_search,
:root:lang(iw) .input_search {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}

.button_search {
    top: 0;
    right: 0px;
    width: 38px;
    height: 45px;
    border: none;
    background: #7BA7AB;
    border-radius: 0 7px 7px 0;
    cursor: pointer;
}

.button_search:before {
    font-family: Roboto;
    font-size: 16px;
    color: #F9F0DA;
}

.style_form {
    display: flex;
}

.input_search_address {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    color: #444;
    line-height: 1.3;
    width: 270px;
    height: 40px;
    padding-left: 10px;
    border: 1px solid #aaa;
    border-radius: 7px 0px 0px 7px;
    outline: none;
    background-color: #fff;
    color: #9E9C9C;
}

.input_search_address::-ms-expand {
    display: none;
}

.input_search_address:hover {
    border-color: #888;
}

.input_search_address:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #7BA7AB;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.input_select_button {
    display: flex;
}

.input_select {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    color: #444;
    line-height: 1.3;
    width: 65px;
    height: 40px;
    padding-left: 10px;
    border: 1px solid #aaa;
    border-radius: 7px 0px 0px 7px;
    outline: none;
    background-color: #fff;
    color: #9E9C9C;
}

.input_select::-ms-expand {
    display: none;
}

.input_select:hover {
    border-color: #888;
}

.input_select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #7BA7AB;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.select_wallets_eth {
    display: block;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    color: #444;
    line-height: 1.0;
    padding: .6em .2em .3em .3em;
    width: 140px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
.select_wallets_eth::-ms-expand {
    display: none;
}

.select_wallets_eth:hover {
    border-color: #888;
}

.select_wallets_eth:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #7BA7AB;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.select_wallets_eth option {
    font-weight: normal;
}

*[dir="rtl"] .select_wallets_eth,
:root:lang(ar) .select_wallets_eth,
:root:lang(iw) .select_wallets_eth {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}

.btn_clear {
    top: 0;
    right: 0px;
    width: 55px;
    height: 45px;
    border: none;
    background: #C43430;
    border-radius: 5px 5px 5px 5px;
    cursor: pointer;

}

.amoutStyle{
    text-align:  center;
}

.buttonStyle{
    display: flex;
    justify-content: space-around;
}
