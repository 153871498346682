.all_content {
    background-color: #F0FFF0;
    padding-bottom: 50px;

}

.table {
    margin-right: 15px;
    margin-left: 15px;
}

.all_button {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 5px;
}

.two_btns {
    display: flex;
    gap: 20px;
}

.btn_add_clear {
    margin-top: 20px;
}

#tab-btn-1:checked~#content-1 {
    display: block;
}

.container_btn_uploader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.all_group_button {
    display: flex;
    align-items: center;
}

.modal_datepicker {
    font-family: Roboto;
}

.th_table {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    display: table-cell;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.datepicker {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.uploader_style {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    color: #383837;
    border: 4px dotted;
    width: 430px;
    height: 200px;

}

.uploader_text {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    margin-top: 45px;
}

.btn_uploader {
    width: 110px;
    height: 35px;
    border-radius: 7px;
    background-color: #64B83D;
    border-color: #64B83D;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 40px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-top: 15px;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 5px;
    border-color: black;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}

ul.pagination li a.active {
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
}

ul.pagination li a:hover:not(.active) {
    background-color: #ddd;
}

.totalBurned {
    display: flex;
    justify-content: center;
    background-color: #9DFC72;
    color: red;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}

.categoryTitle {
    display: flex !important;
    padding: 5px !important;
}

.categoryButton {
    display: flex !important;
}